<template>
  <v-card>
    <v-card-title>
      Call Data
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-btn @click="uploadFile()" color="primary" class="ml-2"
        >Upload File</v-btn
      >
    </v-card-title>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="callData"
      class="elevation-1"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceExcalibur.js";
const service = new RestResource();

export default {
  data() {
    return {
      callData: [],
      search: "",
      headers: [
        { text: "Screener Full Name", value: "name", sortable: true },
        { text: "BCID", value: "bcid", sortable: true },
        { text: "BRID", value: "brid", sortable: true },
        { text: "Status 2C", value: "status2C", sortable: true },
        { text: "Call Attempt 1", value: "callAttempt1", sortable: true },
        { text: "Call Attempt 2", value: "callAttempt2", sortable: false },
        { text: "Call Attempt 3", value: "callAttempt3", sortable: true },
        {
          text: "Jnt Delivery Date",
          value: "JntDdeliveryDate",
          sortable: false,
        },
      ],
    };
  },

  mounted() {
    this.fetchCallData();
  },

  methods: {
    async fetchCallData() {
      this.$setLoader();
      
      await service.fetchCallData().then((res) => {
        this.callData = res.data.data;
      });

      this.$disableLoader();
    },

    uploadFile() {
      this.$router.push({ name: 'ExcaliburCallDataUpload' })
    }

  },
};
</script>